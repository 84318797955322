:root {
  /* COLORS */
  --tecnea-blue: #017db8;
  --tecnea-blue-dark: #02587E;
  --tecnea-blue-light: #009AE7;

  --primary-color: #878787;
  --primary-color-light: #a1a1a1;
  --primary-color-dark: #575757;

  --error-color: #e2001a;
  --error-color-lighter: #fedede;
  --warning-color: #ff762e;
  --success-color: #0ea04b;

  --grey-darker: #404040;
  --grey-dark: #737373;
  --grey: #a6a6a6;
  --grey-light: #d9d9d9;
  --grey-lighter: #ececec;

  --black: #000000;
  --white: #ffffff;

  /* RESPONSIVE WIDTHS */
  --wrapperWidthMax: 1200px;
  --wrapperWidthMin: 320px;

  --tabletWidth: 768px;

  /* MISC */
  --transitionTime: 0.3s;

  --header-nav-height: 6rem;
  --header-item-width-guest: 300px;
  --header-item-width: 160px;

  --header-with-edition-bar-height: 18rem;
  --sidebar-width: 28%;
  --showMore-height: 6rem;


  --header-blue: #0085B1;

  --zindexSmall: 10;
  --zindexMedium: 50;
  --zindexBig: 100;
}

@media (max-width: 540px) {
  :root {
    --header-item-width-guest: 150px;
  }
}
