@import 'styles/_variables.css';
@import 'styles/_fonts.css';

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  min-width: var(--wrapperWidthMin);
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: 'Roboto', 'OpenSans', Arial, Helvetica, sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  body {
    overflow-y: scroll;
  }
}

a {
  color: var(--tecnea-blue);
  text-decoration: none;
  transition: all var(--transitionTime);
}

  a:hover,
  a.active {
    color: var(--tecnea-blue-dark);
    text-decoration: underline;
  }

/* UTILS */
.mr1 {
  margin-right: 0.8rem;
}

.ml1 {
  margin-left: 0.8rem;
}

.bold {
  font-weight: bold !important;
}

/* MATERIAL UI */

/* TOOLTIPS */
body .MuiTooltip-tooltip {
  font-size: 1.4rem;
}

/* MODALS */
body .MuiDialog-paper {
  min-width: var(--wrapperWidthMin);
}

body .MuiDialog-paper form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

body .MuiDialogTitle-root {
  color: var(--white);
  background: var(--primary-color-dark);
}

  body .MuiDialogTitle-root.errorModal {
    background: var(--error-color);
  }

  body .MuiDialogTitle-root.warningModal {
    background: var(--warning-color);
  }

  body .MuiDialogTitle-root.confirmModal {
    background: var(--success-color);
  }

body .MuiDialogContent-root {
  padding-top: 16px;
}

/* TOGGLE BUTTONS */
body .MuiToggleButton-root {
  min-width: 90px;
  color: var(--tecnea-blue);
  border-color: var(--tecnea-blue);
}

body .MuiToggleButton-root.Mui-selected {
  color: var(--white);
  background-color: var(--tecnea-blue);
}

body .MuiToggleButton-root.Mui-selected:hover {
  background-color: var(--tecnea-blue);
}

body .MuiToggleButton-root svg,
body .MuiDialogTitle-root svg {
  margin-right: 1rem;
}

.react-select-error div,
.react-select-error legend.Mui-required,
.react-select-error:hover div {
  color: var(--error-color) !important;
  border-color: var(--error-color);
  box-shadow: none;
}