@font-face {
  font-display: swap;
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}


/* =======
FONT ICON
======= */
@font-face {
  font-family: 'databilan';
  src:  url('./fonts/databilan.eot?6ygv0j');
  src:  url('./fonts/databilan.eot?6ygv0j#iefix') format('embedded-opentype'),
    url('./fonts/databilan.ttf?6ygv0j') format('truetype'),
    url('./fonts/databilan.woff?6ygv0j') format('woff'),
    url('./fonts/databilan.svg?6ygv0j#databilan') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'databilan', 'OpenSans', Arial, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 2.2rem;
  text-transform: none;
  line-height: 1;
  vertical-align: sub;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-scan:before {
  content: "\e900";
}
.icon-send-by-letter:before {
  content: "\e901";
}
.icon-send-by-mail:before {
  content: "\e902";
}
.icon-hazard:before {
  content: "\e903";
}
.icon-leak-detector:before {
  content: "\e904";
}
.icon-intervention:before {
  content: "\fdb4";
}
.icon-assistant:before {
  content: "\fdb3";
}
.icon-container-23 .path1:before {
  content: "\e907";
}
.icon-container-23 .path2:before {
  content: "\e908";
  margin-left: -0.5em;
}
.icon-container-23 .path3:before {
  content: "\e909";
  margin-left: -0.5em;
}
.icon-container-23 .path4:before {
  content: "\e90a";
  margin-left: -0.5em;
}
.icon-container-13 .path1:before {
  content: "\e90b";
}
.icon-container-13 .path2:before {
  content: "\e90c";
  margin-left: -0.5em;
}
.icon-container-13 .path3:before {
  content: "\e90d";
  margin-left: -0.5em;
}
.icon-container-13 .path4:before {
  content: "\e90e";
  margin-left: -0.5em;
}
.icon-container-warning .path1:before {
  content: "\e90f";
}
.icon-container-warning .path2:before {
  content: "\e910";
  margin-left: -0.9248046875em;
}
.icon-container-warning .path3:before {
  content: "\e911";
  margin-left: -0.9248046875em;
}
.icon-container-warning .path4:before {
  content: "\e912";
  margin-left: -0.9248046875em;
}
.icon-container-warning .path5:before {
  content: "\e913";
  margin-left: -0.9248046875em;
}
.icon-container-03 .path1:before {
  content: "\e914";
}
.icon-container-03 .path2:before {
  content: "\e915";
  margin-left: -0.5em;
}
.icon-container-03 .path3:before {
  content: "\e916";
  margin-left: -0.5em;
}
.icon-container-03 .path4:before {
  content: "\e917";
  margin-left: -0.5em;
}
.icon-container:before {
  content: "\e918";
}
.icon-stocks-v2:before {
  content: "\e919";
}
.icon-stocks:before {
  content: "\e91a";
}